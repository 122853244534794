<template>
  <div class="batchBox">
    <div class="conMsg">
      <div class="top">
        <div class="title">
          <i class="el-icon-collection-tag" />基本资料
        </div>
      </div>
      <el-descriptions class="margin-top" :column="2" border>
        <el-descriptions-item>
          <template slot="label">状态</template>
          <span v-if="detail.status == 1 ">激活</span>
          <span v-if="detail.status == 2 ">禁用</span>
          <span v-if="detail.status == 3 ">离职</span>
        </el-descriptions-item>
        <el-descriptions-item v-if="detail.status == 2">
          <template slot="label">禁用原因</template>
          {{detail.reason}}
        </el-descriptions-item>
        <el-descriptions-item v-if="detail.status == 3">
          <template slot="label">离职时间</template>
          {{detail.leaveAt}}
        </el-descriptions-item>
        <el-descriptions-item>
          <template slot="label">所在门店</template>
          {{detail.shopName?detail.shopName:'暂无'}}
        </el-descriptions-item>
        <el-descriptions-item>
          <template slot="label">员工姓名</template>
          {{detail.name}}
        </el-descriptions-item>
        <el-descriptions-item>
          <template slot="label">员工电话</template>
          {{detail.phone}}
        </el-descriptions-item>
        <el-descriptions-item>
          <template slot="label">员工年龄</template>
          {{detail.age}}岁
        </el-descriptions-item>
        <el-descriptions-item>
          <template slot="label">性别</template>
          {{detail.sex}}
        </el-descriptions-item>
        <el-descriptions-item>
          <template slot="label">职位</template>
          {{detail.stationName}}
        </el-descriptions-item>
        <el-descriptions-item>
          <template slot="label">岗位</template>
          {{detail.positionName}}
        </el-descriptions-item>
        <el-descriptions-item>
          <template slot="label">员工生日</template>
          {{detail.birthday}}
        </el-descriptions-item>
        <el-descriptions-item>
          <template slot="label">入职时间</template>
          {{detail.entryAt}}
        </el-descriptions-item>
        <el-descriptions-item>
          <template slot="label">工龄</template>
          {{detail.jobAge}}年
        </el-descriptions-item>
        <el-descriptions-item>
          <template slot="label">试用期</template>
          {{detail.probation}}月
        </el-descriptions-item>
        <el-descriptions-item>
          <template slot="label">是否参与预约</template>
          {{detail.isReservation > 0 ? '是' : '否'}}
        </el-descriptions-item>
        <!-- <el-descriptions-item>
          <template slot="label">试用期</template>
          {{detail.probation}}月
        </el-descriptions-item> -->
        <el-descriptions-item v-if="detail.status == 1">
          <template slot="label"></template>
        </el-descriptions-item>
      </el-descriptions>
    </div>
  </div>
</template>

<script>
import { staffDetail } from "@/api/staff";

class Detail {
  status = 1;
  reason = "";
  shopId = "";
  name = "";
  phone = "";
  age = "";
  sex = "男";
  stationId = "";
  stationName = "";
  positionId = "";
  positionName = "";
  birthday = "";
  entryAt = "";
  jobAge = "";
  probation = "";
  leaveAt = "";
  isReservation = "";
}

class Page {
  total = 0;
  page = 1;
  limit = 10;
}
export default {
  name: "AddStaff",
  components: {},

  data() {
    return {
      detail: new Detail(),
      list: [], // 门店展示
      active: 0,
      shopName: "",
      dialogVisible: false
    };
  },
  mounted() {
    this.getDetail();
  },
  methods: {
    // 获取详情
    getDetail() {
      staffDetail({ id: this.$route.query.id }).then(res => {
        this.detail = res;
      });
    }
  }
};
</script>

<style lang="scss" scoped>
.conMsg {
  margin-bottom: 30px;
  .top {
    display: flex;
    justify-content: space-between;
    .title {
      font-weight: bold;
      font-size: 14px;
      line-height: 34px;
      color: #666;
    }
  }
}
::v-deep .el-descriptions-item__label.is-bordered-label {
  background: #f9fafc;
}
</style>
